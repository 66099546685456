@charset "utf-8";
/* CSS Document */
@page print  {
	size: A5;
	margin: 0;
}
@media print {
	html, body {
		width: 210mm !important;
		margin: 0;
	}
	div, span, a, p, td, input, textarea{
		font-family: Arial, Helvetica, sans-serif;
		font-size: 13px;
		text-decoration: none;
	}
	.hide-print {
		display: none;
	}
	.face * {
		font-size: 20px !important;
	}
	#print_invoice_content{ display: block;}
}
/* fill half the height with each face */
.face {
	width: 210mm !important;
	position: relative;
	padding: 0px 5px;
}
.page-break{
	page-break-after: always;
}
#print_invoice_content{ display: none;}
