body{
  overflow-y: auto;
  overflow-x: hidden !important;
}
.turnDeviceNotification {
  position:fixed;
  top: 0;
  left:0;
  height:100%;
  width:100%;
  display: none;
  background-image:url('./turn_device.png');
  background-size:cover;
}

button{
  outline: none !important;
}